import { Button, useTheme } from '@mui/material';
import {
  DataGrid,
  GridActionsCellItem,
  GridCellParams,
  GridColDef,
  GridEventListener,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowsProp,
  GridSlotProps,
  GridToolbarContainer,
  GridValidRowModel
} from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import { v4 as uuidv4 } from 'uuid';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DeletePopup from 'components/modal/DeletePopup/DeletePopup';

export type RowData = GridValidRowModel & {
  id: string;
  isNew: boolean;
  isDeleteAllowed?: boolean;
};

declare module '@mui/x-data-grid' {
  interface ToolbarPropsOverrides {
    fieldToFocus?: string;
    setRows: (
      newRows: (oldRows: GridRowsProp<RowData>) => GridRowsProp<RowData>
    ) => void;
    setRowModesModel: (
      newModel: (oldModel: GridRowModesModel) => GridRowModesModel
    ) => void;
  }
}

const CrudTableToolbar: React.FC<GridSlotProps['toolbar']> = (
  props: GridSlotProps['toolbar']
) => {
  const { fieldToFocus, setRows, setRowModesModel } = props;

  const { t } = useTranslation('crudTable', {
    keyPrefix: 'toolbar'
  });

  const handleClick = () => {
    const id = uuidv4();
    setRows((oldRows) => [...oldRows, { id, isNew: true }]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus }
    }));
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        {t('add')}
      </Button>
    </GridToolbarContainer>
  );
};

interface CrudTableProps {
  rows: GridRowsProp<RowData>;
  columns: GridColDef[];
  onRowsUpdated: (newRows: RowData[]) => void;
  isCellEditable?: (params: GridCellParams) => boolean;
}

const CrudTable: React.FC<CrudTableProps> = (props: CrudTableProps) => {
  const {
    rows: initialRows,
    columns: dataColumns,
    onRowsUpdated,
    isCellEditable
  } = props;

  /*
   * ************** Providers **************
   */
  const theme = useTheme();
  const { t } = useTranslation('crudTable');

  /*
   * ************** State Vars **************
   */
  const [rows, setRows] = useState<GridRowsProp<RowData>>(initialRows);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [selectedRow, setSelectedRow] = useState<GridRowId | null>(null);
  const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);

  /*
   * ************** Hooks **************
   */

  /*
   * ************** Helper Functions **************
   */
  const handleRowModesModelChanged = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (
    params,
    event
  ) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const processRowUpdate = (newRow: GridRowModel<RowData>) => {
    const updatedRow = { ...newRow, isNew: false };
    const updatedRows = rows.map((row) =>
      row.id === newRow.id ? updatedRow : row
    );

    onRowsUpdated(updatedRows);

    setRows(updatedRows);
    return updatedRow;
  };

  const handleSaveClicked = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleEditClicked = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleDeleteClicked = (id: GridRowId) => () => {
    setSelectedRow(id);
    setShowDeletePopup(true);
  };

  const handleDeleteRow = () => {
    const updatedRows = rows.filter((row) => row.id !== selectedRow);

    setRows(updatedRows);
    setSelectedRow(null);
    setShowDeletePopup(false);
    onRowsUpdated(updatedRows);
  };

  const handleCancelClicked = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true }
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow!.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  /*
   * ************** Render **************
   */
  const columns: GridColDef[] = [
    ...dataColumns,
    {
      field: 'actions',
      type: 'actions',
      headerName: t('columns.actions'),
      headerClassName: 'departments-table-header',
      flex: 0.5,
      getActions: ({ id, row }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              key="save"
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main'
              }}
              onClick={handleSaveClicked(id)}
            />,
            <GridActionsCellItem
              key="cancel"
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClicked(id)}
              color="inherit"
            />
          ];
        }

        const actions = [
          <GridActionsCellItem
            key="edit"
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            color="inherit"
            onClick={handleEditClicked(id)}
          />
        ];
        if (row.isDeleteAllowed) {
          actions.push(
            <GridActionsCellItem
              key="delete"
              icon={<DeleteIcon />}
              label="Delete"
              onClick={handleDeleteClicked(id)}
              color="inherit"
            />
          );
        }

        return actions;
      }
    }
  ];

  return (
    <>
      <DataGrid
        rows={rows}
        columns={columns}
        editMode="row"
        isCellEditable={isCellEditable}
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChanged}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        disableColumnMenu
        hideFooterSelectedRowCount
        pageSizeOptions={[25]}
        initialState={{
          sorting: {
            sortModel: [{ field: columns[0].field, sort: 'asc' }]
          }
        }}
        slots={{ toolbar: CrudTableToolbar }}
        slotProps={{
          toolbar: { setRows, setRowModesModel, fieldToFocus: columns[0].field }
        }}
        sx={{
          px: 1,
          '& .MuiDataGrid-root': {
            borderColor: theme.palette.mode === 'light' ? '#c9c9c9' : '#757575'
          },
          '& .MuiDataGrid-cell': {
            backgroundColor:
              theme.palette.mode === 'light' ? '#f8f8f8' : '#171717',
            borderRight: `1px solid ${
              theme.palette.mode === 'light' ? '#c9c9c9' : '#757575'
            }`
          }
        }}
      />

      {/* Popups */}
      <DeletePopup
        open={showDeletePopup}
        title={t('deletePopup.title')}
        message={t('deletePopup.message')}
        onConfirm={handleDeleteRow}
        onCancel={() => {
          setShowDeletePopup(false);
          setSelectedRow(null);
        }}
      />
    </>
  );
};

export default CrudTable;
