import { useState, useEffect } from 'react';
import {
  useTheme,
  useMediaQuery,
  Chip,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Accordion,
  AccordionSummary,
  TextField,
  Typography,
  AccordionActions,
  Button,
  AccordionDetails,
  OutlinedInput
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';

import { Qna } from 'services/api/models';
import EditQnaPopup from '../../modal/EditQnaPopup/EditQnaPopup';
import { Trans, useTranslation } from 'react-i18next';
import DeletePopup from 'components/modal/DeletePopup/DeletePopup';

interface QnaItemProps {
  item: Qna;
  tags: string[];
  expanded: boolean;
  onSelected: (
    item: Qna
  ) => (event: React.SyntheticEvent, isExpanded: boolean) => void;
  onSave: (
    id: number,
    question: string,
    answer: string,
    tags: string[]
  ) => void;
  onDelete: (item: Qna) => void;
}

const QnaItem: React.FC<QnaItemProps> = (props: QnaItemProps) => {
  const { item, tags, expanded, onSelected, onSave, onDelete } = props;

  /*
   * ************** Providers *********************
   */
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation('knowledgeBase', {
    keyPrefix: 'qna.editor.item'
  });

  /*
   * ************** State Vars *********************
   */
  const [inlineEditing, setInlineEditing] = useState(false);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(item.question);
  const [currentAnswer, setCurrentAnswer] = useState(item.answer);
  const [currentTags, setCurrentTags] = useState(item.tags ?? []);

  /*
   * ************** Hooks *********************
   */
  useEffect(() => {
    setCurrentQuestion(item.question);
    setCurrentAnswer(item.answer);
    setCurrentTags(item.tags ?? []);
  }, [props]);

  /*
   * ************** Helper Function(s) *********************
   */
  const handleTagDeleted = (tagToDelete: string) => {
    const index = currentTags.findIndex((tag) => tag === tagToDelete);
    const updatedTags = [...currentTags];
    updatedTags.splice(index, 1);
    setCurrentTags(updatedTags);
  };

  const handleTagsChanged = (e: SelectChangeEvent<string[]>) => {
    const {
      target: { value }
    } = e;
    // On autofill we get a stringified value
    setCurrentTags(typeof value === 'string' ? value.split(',') : value);
  };

  const handleSave = () => {
    if (item.id) {
      onSave(item.id, currentQuestion, currentAnswer, currentTags);
      setInlineEditing(false);
    }
  };

  const handleEditModeClicked = () => {
    if (matches) {
      // Show a dialog for mobile users
      setShowEditDialog(true);
    } else {
      setInlineEditing(true);
    }
  };

  /*
   * ************** Render *********************
   */
  return (
    <>
      <Accordion
        key={item.id}
        expanded={expanded}
        onChange={inlineEditing ? undefined : onSelected(item)}
      >
        <AccordionSummary expandIcon={!inlineEditing && <ExpandMoreIcon />}>
          {inlineEditing ? (
            <>
              <Box sx={{ display: 'flex', flexGrow: 0.75 }}>
                <TextField
                  label={t('questionLabel')}
                  fullWidth
                  variant="outlined"
                  value={currentQuestion}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setCurrentQuestion(event.target.value)
                  }
                />
              </Box>

              <Box
                sx={{ ml: theme.spacing(2), display: 'flex', flexGrow: 0.25 }}
              >
                <FormControl sx={{ flexGrow: 1 }}>
                  <InputLabel id="select-tag-label">
                    {t('tagsLabel')}
                  </InputLabel>
                  <Select
                    labelId="select-tag-label"
                    multiple
                    fullWidth
                    value={currentTags}
                    onChange={handleTagsChanged}
                    input={<OutlinedInput label={t('tagsLabel')} />}
                    sx={{
                      '& .MuiInputBase-input': {
                        padding: '12.8px 14px'
                      }
                    }}
                    renderValue={(selected) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip
                            key={value}
                            label={value}
                            sx={{
                              backgroundColor:
                                theme.palette.mode === 'light'
                                  ? 'rgba(157, 205, 255, 0.49)'
                                  : 'rgba(26, 138,255, 0.49)',
                              mx: '2px'
                            }}
                            onMouseDown={(e) => e.stopPropagation()}
                            onDelete={() => handleTagDeleted(value)}
                          />
                        ))}
                      </Box>
                    )}
                  >
                    {tags.map((tag) => (
                      <MenuItem key={tag} value={tag}>
                        {tag}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </>
          ) : (
            <>
              <Box sx={{ display: 'flex', flexGrow: 0.75 }}>
                <Typography>{currentQuestion}</Typography>
              </Box>

              <Box
                sx={{
                  mr: theme.spacing(1),
                  display: 'flex',
                  flexGrow: 0.25,
                  justifyContent: 'flex-end'
                }}
              >
                {currentTags.map((tag, index) => (
                  <Chip
                    key={index}
                    label={tag}
                    sx={{
                      backgroundColor:
                        theme.palette.mode === 'light'
                          ? 'rgba(157, 205, 255, 0.49)'
                          : 'rgba(26, 138,255, 0.49)',
                      mx: '2px'
                    }}
                  />
                ))}
              </Box>
            </>
          )}
        </AccordionSummary>
        <AccordionDetails>
          {inlineEditing ? (
            <TextField
              label={t('answerLabel')}
              multiline
              fullWidth
              value={currentAnswer}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setCurrentAnswer(event.target.value)
              }
            />
          ) : (
            <Typography>
              <Trans>
                <strong>{t('answerLabel')}:</strong> {{ currentAnswer }}
              </Trans>
            </Typography>
          )}
        </AccordionDetails>
        <AccordionActions
          sx={{
            pl: theme.spacing(2),
            borderTop: 'inset',
            borderTopWidth: '1px',
            justifyContent: 'flex-end'
          }}
        >
          <Button
            variant="contained"
            color="error"
            startIcon={inlineEditing ? <CloseIcon /> : <DeleteIcon />}
            onClick={
              inlineEditing
                ? () => setInlineEditing(false)
                : () => setShowDeleteDialog(true)
            }
          >
            {inlineEditing ? t('cancelButton') : t('deleteButton')}
          </Button>
          <Button
            variant="contained"
            color="info"
            startIcon={inlineEditing ? <SaveIcon /> : <ModeEditIcon />}
            onClick={inlineEditing ? handleSave : handleEditModeClicked}
          >
            {inlineEditing ? t('saveButton') : t('editButton')}
          </Button>
        </AccordionActions>
      </Accordion>

      {/* Popups */}
      <EditQnaPopup
        item={item}
        tags={tags}
        open={showEditDialog}
        onClose={() => setShowEditDialog(false)}
        onSave={(id, q, a, tags) => {
          setShowEditDialog(false);
          onSave(id, q, a, tags);
        }}
      />
      <DeletePopup
        open={showDeleteDialog}
        title={t('popup.delete.title')}
        message={
          <Trans>
            {t('popup.delete.message')} &apos;{{ question: item.question }}
            &apos;?
          </Trans>
        }
        onConfirm={() => {
          setShowDeleteDialog(false);
          onDelete(item);
        }}
        onCancel={() => setShowDeleteDialog(false)}
      />
    </>
  );
};

export default QnaItem;
