import {
  Box,
  Skeleton,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { useState } from 'react';

import AccountInfoTab from './tabs/AccountInfoTab';
//import SubscriptionInformationTab from './components/ SubscriptionInformationTab';
import CustomTabPanel from 'components/CustomTabPanel/CustomTabPanel';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useAlerts } from 'hooks/useAlerts';
import GroupTagsTab from './tabs/GroupTagsTab';
import { useAppServices } from 'hooks/useAppServices';
import { AccountUpdateRequestBody, Department } from 'services/api/models';
import { useAuth } from 'hooks/useAuth';
import { haveCommonElements } from 'util/arrays';
import { AppRoles } from 'config/authConfig';
import { useTranslation } from 'react-i18next';
import DepartmentsTab from './tabs/DepartmentsTab';

const OptionsPage: React.FC = () => {
  /*
   * ************** Providers *********************
   */
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const queryClient = useQueryClient();
  const { dataService } = useAppServices();
  const { sendAlert } = useAlerts();
  const { userRoles } = useAuth();
  const { t } = useTranslation('settings');

  /*
   * ************** State Vars *********************
   */
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  /*
   * ************** Hooks *********************
   */
  const {
    data: account,
    error,
    isLoading,
    isError
  } = useQuery({
    queryKey: ['account'],
    queryFn: () => dataService.getAccount(),
    staleTime: 1800000 // 30 minutes
  });

  const updateAccountMutation = useMutation({
    mutationFn: async (account: AccountUpdateRequestBody) =>
      await dataService.updateAccount(account),
    onSuccess: () => {
      sendAlert({
        severity: 'info',
        message: t('alert.success')
      });

      queryClient.invalidateQueries(['account']);
    },
    onError: (error) => handleError(error as Error)
  });

  /*
   * ************** Helper Functions *********************
   */
  const handleTabSelected = (_event: React.SyntheticEvent, index: number) => {
    setSelectedTabIndex(index);
  };

  const handleError = (err: Error) => {
    console.error('The API operation failed with the following error', err);
    sendAlert({
      severity: 'error',
      message: `${t('alert.error')}: ${err.message}`
    });
  };

  const handleUpdate = (accountUpdate: AccountUpdateRequestBody) => {
    updateAccountMutation.mutate(accountUpdate);
  };

  const handleDepartmentsUpdated = (departments: Department[]) => {
    const generalDepartment = departments.find(
      (department) => department.name === 'General'
    );
    const defaultKnowledgeMasterEmail = generalDepartment?.knowledgeMasterEmail;

    const customDepartments = departments.filter(
      (department) => department.name !== 'General'
    );
    handleUpdate(
      new AccountUpdateRequestBody(
        undefined,
        defaultKnowledgeMasterEmail,
        undefined,
        customDepartments
      )
    );
  };

  /*
   * ************** Render *********************
   */
  const canViewAdminTabs = haveCommonElements(userRoles, [
    AppRoles[AppRoles.Administrator]
  ]);

  if (isError) {
    handleError(error as Error);
    return (
      <Box
        sx={{
          px: {
            md: theme.spacing(3),
            sm: theme.spacing(2),
            xs: theme.spacing(1)
          }
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'space-between',
            mt: theme.spacing(6),
            mb: theme.spacing(2)
          }}
        >
          <Typography variant="h2">{t('title')}</Typography>
          <Typography variant="body2">{t('errorPage')}</Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        px: {
          md: theme.spacing(3),
          sm: theme.spacing(2),
          xs: theme.spacing(1)
        }
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'space-between',
          mt: theme.spacing(6),
          mb: theme.spacing(2)
        }}
      >
        <Typography variant="h2">{t('title')}</Typography>
      </Box>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: theme.palette.divider
        }}
      >
        <Tabs
          value={selectedTabIndex}
          onChange={handleTabSelected}
          aria-label="tabs"
          textColor={theme.palette.mode === 'light' ? 'primary' : 'inherit'}
          TabIndicatorProps={{
            sx: {
              backgroundColor:
                theme.palette.mode === 'dark'
                  ? theme.palette.text.secondary
                  : ''
            }
          }}
          variant={isSmallScreen ? 'scrollable' : 'standard'}
          scrollButtons={isSmallScreen ? true : false}
        >
          <Tab label={t('tabs.accountInfo')} />
          {canViewAdminTabs && [
            <Tab key="departments" label={t('tabs.departments')} />,
            <Tab key="groupTags" label={t('tabs.groupTags')} />
          ]}
        </Tabs>
      </Box>

      <Box
        sx={{
          border: `1px solid ${theme.palette.divider}`,
          padding: '5px',
          mt: 4
        }}
      >
        {isLoading ? (
          <Box sx={{ display: 'flex' }}>
            <Skeleton
              variant="rounded"
              animation="wave"
              width="100%"
              height={theme.spacing(60)}
            />
          </Box>
        ) : (
          <>
            <CustomTabPanel value={selectedTabIndex} index={0}>
              <AccountInfoTab account={account} onError={handleError} />
            </CustomTabPanel>
            {canViewAdminTabs && (
              <>
                <CustomTabPanel value={selectedTabIndex} index={1}>
                  <DepartmentsTab
                    departments={[
                      {
                        name: 'General',
                        knowledgeMasterEmail:
                          account.defaultKnowledgeMasterEmail
                      },
                      ...account.departments
                    ]}
                    onUpdate={handleDepartmentsUpdated}
                    onError={handleError}
                  />
                </CustomTabPanel>
                <CustomTabPanel value={selectedTabIndex} index={2}>
                  <GroupTagsTab
                    groupTags={account.groupTags}
                    onUpdate={(groups) =>
                      handleUpdate(
                        new AccountUpdateRequestBody(
                          undefined,
                          undefined,
                          groups
                        )
                      )
                    }
                    onError={handleError}
                  />
                </CustomTabPanel>
              </>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default OptionsPage;
