import { ApiClient } from './apiClient';
import { ChatMessage, ChatRequest, ChatOptions } from 'services/api/models';
import { protectedResources } from 'config/authConfig';
import { ApiKeyAuthorizationProvider } from './authorizationProvider';

export class ChatApiClient extends ApiClient {
  constructor(tenantId: string, apiKey: string) {
    super(
      protectedResources.chatApi.baseUrl,
      new ApiKeyAuthorizationProvider(tenantId, apiKey)
    );
  }

  async chatStream(messages: ChatMessage[], options?: ChatOptions) {
    const endpoint = `/chat`;
    const request: ChatRequest = {
      messages,
      stream: true,
      context: options?.context,
      session_state: options?.session
    };
    return this.postStream(endpoint, {
      requestBody: request
    });
  }

  async alert(
    userName: string,
    userEmail: string,
    transcriptFile: File,
    knowledgeMasterEmail?: string
  ) {
    const endpoint = `/alert`;

    // Create the multipart body
    const form = new FormData();
    form.append('userName', userName);
    form.append('userEmail', userEmail);
    form.append('file', transcriptFile);

    if (knowledgeMasterEmail) {
      form.append('knowledgeMasterEmail', knowledgeMasterEmail);
    }

    return this.post(endpoint, {
      requestBody: form
    });
  }
}
