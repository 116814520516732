import { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  TextField,
  DialogActions,
  Button,
  useTheme,
  FormControl,
  InputLabel,
  Select,
  Chip,
  MenuItem,
  SelectChangeEvent,
  OutlinedInput
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

interface CreateQnaPopupProps {
  tags: string[];
  open: boolean;
  onClose: () => void;
  onSave: (question: string, answer: string, tags: string[]) => void;
}

const CreateQnaPopup: React.FC<CreateQnaPopupProps> = (
  props: CreateQnaPopupProps
) => {
  const { tags, open, onClose, onSave } = props;

  /*
   * ************** Providers *********************
   */
  const theme = useTheme();
  const { t } = useTranslation('knowledgeBase', {
    keyPrefix: 'qna.editor.item.popup.create'
  });

  /*
   * ************** State Vars *********************
   */
  const [currentQuestion, setCurrentQuestion] = useState<string>('');
  const [currentAnswer, setCurrentAnswer] = useState<string>('');
  const [currentTags, setCurrentTags] = useState<string[]>([]);

  /*
   * ************** Helper Functions *********************
   */
  const handleTagsChanged = (e: SelectChangeEvent<string[]>) => {
    const {
      target: { value }
    } = e;
    // On autofill we get a stringified value
    setCurrentTags(typeof value === 'string' ? value.split(',') : value);
  };

  const handleSaveClicked = () => {
    const q = currentQuestion;
    const a = currentAnswer;
    const tags = currentTags;
    setCurrentQuestion('');
    setCurrentAnswer('');
    setCurrentTags([]);
    onSave(q, a, tags);
  };

  const handleCancelClicked = () => {
    setCurrentQuestion('');
    setCurrentAnswer('');
    setCurrentTags([]);
    onClose();
  };

  /*
   * ************** Render *********************
   */
  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle>{t('title')}</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            my: theme.spacing(2)
          }}
        >
          <TextField
            fullWidth
            label={t('questionLabel')}
            value={currentQuestion}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setCurrentQuestion(event.target.value)
            }
          />
          <TextField
            sx={{
              my: theme.spacing(1)
            }}
            fullWidth
            multiline
            label={t('answerLabel')}
            value={currentAnswer}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setCurrentAnswer(event.target.value)
            }
          />
          <Box sx={{ display: 'flex' }}>
            <FormControl sx={{ flexGrow: 1 }}>
              <InputLabel>{t('tagsLabel')}</InputLabel>
              <Select
                multiple
                value={currentTags}
                onChange={handleTagsChanged}
                input={<OutlinedInput label={t('tagsLabel')} />}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip
                        key={value}
                        label={value}
                        sx={{
                          backgroundColor:
                            theme.palette.mode === 'light'
                              ? 'rgba(157, 205, 255, 0.49)'
                              : 'rgba(26, 138,255, 0.49)',
                          mx: '2px'
                        }}
                      />
                    ))}
                  </Box>
                )}
              >
                {tags.map((tag) => (
                  <MenuItem key={tag} value={tag}>
                    {tag}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="error"
          startIcon={<CloseIcon />}
          onClick={handleCancelClicked}
        >
          {t('cancelButton')}
        </Button>
        <Button
          variant="contained"
          color="info"
          startIcon={<SaveIcon />}
          onClick={handleSaveClicked}
        >
          {t('saveButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateQnaPopup;
