import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import { ReactNode } from 'react';

interface DeletePopupProps {
  open: boolean;
  title: string;
  message: ReactNode;
  onConfirm: () => void;
  onCancel: () => void;
}

const DeletePopup: React.FC<DeletePopupProps> = (props: DeletePopupProps) => {
  const { open, title, message, onConfirm, onCancel } = props;

  /*
   * ************** Providers **************
   */
  const { t } = useTranslation('modal', {
    keyPrefix: 'delete'
  });

  /*
   * ************** Render **************
   */

  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Typography>{message}</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="error"
          startIcon={<DeleteIcon />}
          onClick={onConfirm}
        >
          {t('confirmButton')}
        </Button>
        <Button
          variant="contained"
          startIcon={<CloseIcon />}
          onClick={onCancel}
        >
          {t('cancelButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeletePopup;
