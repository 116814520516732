import { useTranslation } from 'react-i18next';
import { Box, Typography, useTheme } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { v4 as uuidv4 } from 'uuid';

import { Department } from 'services/api/models';
import { DarkModeColorPalette, LightModeColorPalette } from 'styles/colors';
import CrudTable, { RowData } from 'components/CrudTable/CrudTable';

type DepartmentRowData = RowData & {
  departmentName: string;
  knowledgeMasterEmail: string;
};

interface DepartmentsTabProps {
  departments: Department[];
  onUpdate: (departments: Department[]) => void;
  onError?: (error: Error) => void;
}

const DepartmentsTab: React.FC<DepartmentsTabProps> = (
  props: DepartmentsTabProps
) => {
  const { departments, onUpdate, onError } = props;

  /*
   * ************** Providers **************
   */
  const theme = useTheme();
  const { t } = useTranslation('settings', {
    keyPrefix: 'departments'
  });

  /*
   * ************** Helper Functions **************
   */
  const convertRowsToDepartments = (rows: DepartmentRowData[]): Department[] =>
    rows.map((row) => ({
      name: row.departmentName,
      knowledgeMasterEmail: row.knowledgeMasterEmail
    }));

  const hasDuplicateDepartments = (departments: Department[]): boolean => {
    const seenNames = new Set();
    for (const department of departments) {
      const name = department.name;
      if (seenNames.has(name)) {
        return true;
      }
      seenNames.add(name);
    }

    return false;
  };

  const handleRowsUpdated = (rows: DepartmentRowData[]) => {
    const updatedDepartments = convertRowsToDepartments(rows);
    if (hasDuplicateDepartments(updatedDepartments)) {
      onError && onError(new Error('Department name already exists'));
      throw new Error('Department name already exists');
    }

    onUpdate(updatedDepartments);
  };

  /*
   * ************** Render **************
   */
  const rows: DepartmentRowData[] = departments.map((department) => ({
    id: uuidv4(),
    departmentName: department.name,
    knowledgeMasterEmail: department.knowledgeMasterEmail,
    isNew: false,
    isDeleteAllowed: department.name !== 'General'
  }));

  const columns: GridColDef[] = [
    {
      field: 'departmentName',
      headerName: t('columns.departmentName'),
      headerClassName: 'departments-table-header',
      width: 300,
      editable: true
    },
    {
      field: 'knowledgeMasterEmail',
      headerName: t('columns.knowledgeMasterEmail'),
      headerClassName: 'departments-table-header',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      editable: true
    }
  ];

  return (
    <>
      <Box sx={{ mb: theme.spacing(3) }}>
        <Typography variant="h4">{t('title')}</Typography>
        <Typography sx={{ mt: theme.spacing(1) }} variant="body1">
          {t('instructions')}
        </Typography>
      </Box>

      <Box
        sx={{
          minHeight: 300,
          width: { md: '45%' },
          display: 'flex',
          flexDirection: 'column',
          '& .departments-table-header': {
            backgroundColor:
              theme.palette.mode === 'light'
                ? LightModeColorPalette.headerBarBackground
                : DarkModeColorPalette.headerBarBackground,
            color:
              theme.palette.mode === 'light'
                ? LightModeColorPalette.textAlternate
                : DarkModeColorPalette.textAlternate,
            fontSize: '1.3vmin'
          },
          '& .actions': {
            color: 'text.secondary'
          },
          '& .textPrimary': {
            color: 'text.primary'
          }
        }}
      >
        <CrudTable
          rows={rows}
          columns={columns}
          onRowsUpdated={(newRows) =>
            handleRowsUpdated(newRows as DepartmentRowData[])
          }
          isCellEditable={(params) =>
            params.field !== 'departmentName' ||
            (params.field == 'departmentName' && params.value !== 'General')
          }
        />
      </Box>
    </>
  );
};

export default DepartmentsTab;
