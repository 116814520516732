import { PaletteMode } from '@mui/material';
import { TFunction } from 'i18next';
import { renderToStaticMarkup } from 'react-dom/server';
import { Citation } from 'services/api/models';
import { Converter } from 'showdown';

import { DarkModeColorPalette, LightModeColorPalette } from 'styles/colors';

export type HtmlParsedAnswer = {
  answerHtml: string;
  citations: Array<{ name: string }>;
  sourceFiles: Record<string, string>;
  pageNumbers: Record<string, number>;
  showAlertButton?: boolean;
};

export const parseMessageToHtml = (
  message: string,
  dataPoints: Record<string, Partial<Citation>>,
  paletteMode: PaletteMode,
  t: TFunction,
  appendCitations?: boolean,
  showAlertButton?: boolean,
  onCitationClicked?: (fileOptions?: {
    filePath: string;
    sourcePath: string;
    page: string;
  }) => void
): HtmlParsedAnswer => {
  const converter = new Converter();
  const citations: Array<{ name: string }> = [];
  const citationRefs: Record<string, number> = {};
  const sourceFiles: Record<string, string> = {};
  const pageNumbers: Record<string, number> = {};

  // trim any whitespace from the end of the answer
  let parsedAnswer = message.replaceAll('\n', '<br>').trim();
  while (parsedAnswer.endsWith('<br>')) {
    parsedAnswer = parsedAnswer.slice(0, -4);
  }

  // Don't waste time parsing citations if we don't need them
  if (!appendCitations || showAlertButton) {
    const answerHtml = converter.makeHtml(parsedAnswer);
    return {
      answerHtml,
      citations: [],
      sourceFiles: {},
      pageNumbers: {},
      showAlertButton
    };
  }

  // Split the answer into parts, where the odd parts are citations
  const parts = parsedAnswer.split(/\[([^\]]+)\]/g);
  const fragments: string[] = parts.map((part, index) => {
    if (index % 2 === 0) {
      // Even parts are just text
      return part;
    } else {
      // Odd parts are citations
      const citation = dataPoints[part];

      if (!citation) {
        // Return an empty string to avoid throwing an error while the citation
        // begins streaming
        return '';
      } else {
        const fileName = citation.title ?? '';
        const docTitle = fileName.replace(/\.[^/.]+$/, '');
        const citationName: string = `${docTitle}${
          citation.section !== undefined
            ? `, ${t('citationTitleSection')} ${citation.section}`
            : ''
        }`;

        let citationIndex: number;
        if (citationRefs[part] !== undefined) {
          // Multiple references to the same document should have the same reference number
          citationIndex = citationRefs[part];
        } else {
          citations.push({
            name: citationName
          });

          // switch these to the citationShortName as key to allow dynamic lookup of the source path and page number
          // The "FileX" moniker will not be used beyond this point in the UX code
          sourceFiles[citationName] = fileName;

          // Check if the page_number property is a valid number.
          if (!isNaN(Number(citation.page))) {
            const pageNumber: number = Number(citation.page);
            pageNumbers[citationName] = pageNumber;
          } else {
            // The page_number property is not a valid number, but we still generate a citation.
            pageNumbers[citationName] = 0;
          }
          citationIndex = citations.length;
          citationRefs[part] = citationIndex;
        }

        const filePath = citation.title ?? '';
        const sourcePath = citation.title ?? '';
        const page = citation.page ?? '';

        const onClick = onCitationClicked
          ? () =>
              onCitationClicked({
                filePath,
                sourcePath,
                page
              })
          : undefined;

        return renderToStaticMarkup(
          <a
            style={{ cursor: 'pointer', margin: '0 1px' }}
            title={citationName}
            onClick={onClick}
          >
            <sup
              style={{
                border: '1px solid rgba(0, 0, 0, 0.12)',
                backgroundColor:
                  paletteMode === 'dark'
                    ? DarkModeColorPalette.navMenuBackground
                    : LightModeColorPalette.navMenuBackground
              }}
            >
              <strong>{citationIndex}</strong>
            </sup>
          </a>
        );
      }
    }
  });

  const answerHtml = converter.makeHtml(fragments.join(''));
  return {
    answerHtml,
    citations,
    sourceFiles,
    pageNumbers
  };
};
