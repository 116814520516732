import React from 'react';
import {
  Box,
  Grid,
  Typography,
  useTheme,
  InputLabel,
  TextField
} from '@mui/material';

import { AccountsResponse } from 'services/api/models';
import { useTranslation } from 'react-i18next';

export interface AccountInfoTabProps {
  account: AccountsResponse;
  onError: (error: Error) => void;
}

const AccountInfoTab: React.FC<AccountInfoTabProps> = (props) => {
  const { account } = props;

  /*
   * ************** Providers *********************
   */
  const theme = useTheme();
  const { t } = useTranslation('settings', {
    keyPrefix: 'accountInfo'
  });

  /*
   * ************** Render *********************
   */
  return (
    <>
      <Box>
        <Typography variant="h4">{account.name}</Typography>
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Grid container spacing={2} style={{ marginTop: '20px' }}>
          <Grid item xs={12}>
            <Typography
              variant="h6"
              sx={{ mb: theme.spacing(2), fontWeight: '700' }}
            >
              {t('title')}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ mb: theme.spacing(1) }}>
              <InputLabel
                sx={{
                  mb: 1,
                  color: theme.palette.text.primary
                }}
              >
                <Typography variant="subtitle1">{t('tenantId')}</Typography>
              </InputLabel>
              <TextField
                fullWidth
                value={account.tenantId}
                sx={{
                  background: theme.palette.background.paper
                }}
                InputProps={{ readOnly: true }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default AccountInfoTab;
